/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useContext, useEffect } from "react"
import { VariationsProd } from "../../../interfaces/ServerInterfaces"
import VariationAttributes from "./VariationAttributes"
import { ProductInfoContex } from "./ModalProduct";


interface FilterState {
   [key: string]: string;
}

const ProductVariations = ({ variations }: { variations: VariationsProd[] }) => {

   const {
      setCurrentPrice,
      setCurrentDescription,
      setCurrentCodeCurrency,
      originalInfo,
      setResultVariation
   } = useContext(ProductInfoContex);

   //Defining attributes of variation
   const [attributes, setAttributes] = useState({});


   useEffect(() => {
      const updatedAttributes: any = {};

      variations.forEach((variation) => {
         if (variation.stockAreaVariations.length >= 1) {
            variation.attributes.forEach((attribute) => {
               const { code, value, name } = attribute;

               if (!updatedAttributes[code]) {
                  updatedAttributes[code] = { items: [value], name, code };
               } else if (!updatedAttributes[code].items.includes(value)) {
                  updatedAttributes[code].items.push(value);
               }
            });
         }
      });

      setAttributes(updatedAttributes);
   }, []);

   // Initializing filters dependent on attributes
   const initialFilterState: any = Object.values(attributes).reduce((acc: any, attribute: any) => {
      acc[attribute.code] = 'null';
      return acc;
   }, {});

   //Defining filters
   const [filterState, setFilterState] = useState<FilterState>(initialFilterState);
   function filterResultVariation(filterState: FilterState) {
      const resultValues = Object.values(filterState).filter((value) => value !== "null");
      const filteredVariations = variations.filter((item) => {
         const matchAttributes = resultValues.every((value) => {
            return item.attributes.some((attribute) => {
               return attribute.value === value;
            });
         });
         return matchAttributes;
      });
      return filteredVariations;
   }

   const resultVariation = filterResultVariation(filterState);

   function cleanSelection() {
      setFilterState(initialFilterState);
   }

   if (resultVariation.length === 1) {
      const variation = resultVariation[0];
      setResultVariation(variation)
      if (variation.price !== null) {
         setCurrentPrice(resultVariation[0].price.amount)
      }
      if (variation.description !== null) {
         setCurrentDescription(resultVariation[0].description)
      }
      if (variation.price !== null) {
         setCurrentCodeCurrency(resultVariation[0].price.codeCurrency)
      }
   } else {
      setResultVariation(null)
      setCurrentPrice(originalInfo?.price)
      setCurrentDescription(originalInfo?.description)
      setCurrentCodeCurrency(originalInfo?.codeCurrency)
   }

   return (
      <>
         {Object.values(filterState).some((value) => value !== "null") && (
            <span
               className="absolute right-10 text-blue-950 font-bold cursor-pointer"
               onClick={cleanSelection}
            >
               Limpiar selección
            </span>
         )}

         {Object.values(attributes).map((attrib: any, key: number) => (
            <VariationAttributes
               key={key}
               selectedAttribute={filterState[attrib.code]}
               setSelectedAttribute={(value: any) => {
                  setFilterState({ ...filterState, [attrib.code]: value })
               }}
               attributeArray={attrib.items}
               resultVariation={resultVariation}
               attribute={attrib.name}
            />
         )
         )}

      </>
   )
}

export default ProductVariations


