import img from "../assets/logo_full.jpg"

export default function Loading() {
  return (
    <div className='flex h-screen w-screen justify-center items-center '>
      <img
        className="h-32 w-32"
        src={img}
      />
      <div className='bg-gray-500 animate-ping h-7 w-7 rounded-full absolute mt-14 ml-16' />
    </div>
  )
}
