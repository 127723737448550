/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
  faClock,
  faHeart,
  faMugHot,
  faUserCircle,
  faTrashAlt,
  faPlusCircle,
  faMinusCircle,
  faTags,
  faSignOut,
  faArrowRight,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";

import {
  BusinessInterface,
  CategoryInterface,
  ProductInterface,
  StoreSections,
} from "../interfaces/ServerInterfaces";
import APIServer from "../services/APIServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardCategory } from "../components/CardCategory";
import { CardProduct } from "../components/CardProduct";
import ProductForCategory from "../components/ProductForCategory";
import ModalProduct from "../components/modals/modalProduct/ModalProduct";
import ModalGallery from "../components/ModalGallery";
import {
  filterOrdenStatus,
  findMatchingProduct,
  formatDateTime,
  normalizeString,
  printPriceWithCommasAndPeriods,
  scrollToAmount,
  scrollToSection,
  scrollToTop,
} from "../utils/functions";
import PageNotFound from "../components/PageNotFound";
import imageDefault from "../assets/jpg/no-imagen-shop.jpg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setResultState,
  setSearchInputValue,
} from "../store/slices/resultsReducer";
import ModalRegister from "../components/modals/modalLoginRegister/ModalRegister";
import ModalLogIn from "../components/modals/modalLoginRegister/ModalLogIn";
import Modal from "../components/modals/GenericModal";
import ProfileInfo from "../container/profile/ProfileInfo";
import OrdersHistory from "../container/orders/OrdersHistory";
import PaymentWayForm from "../container/paymentWayForm/PaymentWayForm";
import { useAppSelector } from "../store/hooks";
import useServer from "../services/useServerMain";
import { ProductCartInterface } from "../interfaces/LocalInterfaces";
import {
  setBusinessId,
  setCurrentCurrency,
  setUser,
  set_minimun_amount_to_buy_with_delivery,
} from "../store/slices/SessionSlice";
import ModalCheckoutForm from "../components/ModalCheckoutForm";
import { useServerOrders } from "../services/useServerOrders";
import { setInitProductsCar } from "../store/slices/InitSlice";
import Header from "../container/shopPage/Header";
import WhatsAppButton from "../components/WhatsAppButton";
import LoadingShop from "../components/LoadingShop";

interface PersonalInfoInterface {
  name: string;
  description: string;
  address: string;
}

export const BusinessShopPage = () => {
  const { initProductsCar } = useAppSelector((state) => state.init);

  const { logOut } = useServer();
  const { CancelOrder, CreateTropipayLink } = useServerOrders();

  const slug = "la-especial";
  // const { slug } = useParams();
  const { search } = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [business, setBusiness] = useState<BusinessInterface>();

  const [suggestedProducts, setSuggestedProducts] = useState<
    Array<ProductInterface>
  >([]);

  const [cartShopping, setCartShopping] = useState<Array<ProductCartInterface>>(
    initProductsCar?.find((element) => element.businessName === business?.name)
      ?.initProductsCar! ?? []
  );
  const [onSaleProducts, setOnSaleProducts] = useState<Array<ProductInterface>>(
    []
  );
  const [saleCategories, setSaleCategories] = useState<
    Array<CategoryInterface>
  >([]);

  const { user, key: userKey } = useAppSelector((state) => state.session);
  const { orders } = useAppSelector((state) => state.orders);

  const [actualView, setActualView] = useState<string>("shop");

  const [availabilityAlert, setAvailabilityAlert] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [openLogOutModal, setOpenLogOutModal] = useState<boolean>(false);
  const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
  const [showModalLogIn, setShowModalLogIn] = useState<boolean>(false);
  const [deleteCarWarning, setDeleteCarWarning] = useState<{
    status: boolean;
    index: number | null;
  }>({ status: false, index: null });
  const [showModalCheckoutForm, setShowModalCheckoutForm] =
    useState<boolean>(false);
  const [deleteOrderModal, setDeleteOrderModal] = useState<{
    state: boolean;
    id: number | null;
  }>({
    state: false,
    id: null,
  });
  const [seletedProduct, setSeletedProduct] = useState<ProductInterface | null>(
    null
  );
  const [products, setProducts] = useState<Array<StoreSections>>([]);
  const [indexGallery, setIndexGallery] = useState<number>(0);
  const [showModalGallery, setShowModalGallery] = useState<boolean>(false);
  const [pageNotFound404, setPageNotFound404] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [userOpen, setUserOpen] = useState<boolean>(false);
  // const [subTotal, setSubTotal] = useState<number>(0);
  const [isLoggingToPay, setIsLoggingToPay] = useState<boolean>(false);

  const [subTotal, setSubTotal] = useState<
    { prices: number; codeCurrency: string }[]
  >([]);
  const [productResults, setProductResults] = useState<ProductInterface[]>();
  const [
    products_with_no_salesCategories,
    set_products_with_no_salesCategories,
  ] = useState<StoreSections>();

  const searchResultsActive = useSelector((state: RootState) => state.UI.value);
  const { currentCurrency, minimun_amount_to_buy_with_delivery } = useSelector(
    (state: RootState) => state.session
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.UI.searchInputValue
  );
  const dispatch = useDispatch();

  const [online_shop_main_currency, set_online_shop_main_currency] = useState<
    { value: string; name: string; href: string; current: boolean }[]
  >([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsToRemove = ["fbclid", "igshid"];

    paramsToRemove.forEach((param) => {
      if (searchParams.has(param)) searchParams.delete("fbclid");
    });

    // Construir la nueva URL sin el parámetro fbclid
    const newSearch = searchParams.toString();
    const newUrl = `${location.pathname}${newSearch ? "?" + newSearch : ""}`;

    // Navegar a la nueva URL
    navigate(newUrl, { replace: true });
  }, []);

  useEffect(() => {
    //async information loading
    (async () => {
      await Promise.all([
        APIServer.get(`/public/business/${slug}`).then((resp) => {
          return resp;
        }),
        APIServer.get(`/public/products/business/${slug}?suggested=true`),
        APIServer.get(`/public/products/business/${slug}?onSale=true`),
        APIServer.get(`/public/categories/${slug}`),
        APIServer.get(`/public/products/business/${slug}?per_page=200`).then(
          (resp) => {
            return resp;
          }
        ),
      ])
        .then((resp) => {
          if (userKey === null) {
            dispatch(setUser(null));
          }

          const onlineShopMainCurrency =
            resp[0].data.configurationsKey
              .find(
                (config: { key: string }) =>
                  config.key === "online_shop_main_currency"
              )
              ?.value.split(",") ?? [];

          let minimun_amount_to_buy_with_delivery =
            JSON.parse(
              resp[0].data.configurationsKey.find(
                (config: { key: string }) =>
                  config.key === "minimun_amount_to_buy_with_delivery"
              )?.value
            ) ?? [];

          if (Object.keys(minimun_amount_to_buy_with_delivery).length === 0) {
            minimun_amount_to_buy_with_delivery = [];
          }

          dispatch(
            set_minimun_amount_to_buy_with_delivery(
              minimun_amount_to_buy_with_delivery
            )
          );

          let setMainCurrencies = onlineShopMainCurrency.map(
            (config: any, index: number) => ({
              value: config,
              name: config,
              href: "#",
              current: currentCurrency === config,
            })
          );

          if (
            setMainCurrencies.filter(
              (curr: { current: boolean }) => curr.current
            ).length === 0
          ) {
            setMainCurrencies[0].current = true;
          }

          dispatch(
            setCurrentCurrency(
              setMainCurrencies.find(
                (curr: { current: boolean }) => curr.current
              ).value
            )
          );

          set_online_shop_main_currency(setMainCurrencies);

          setBusiness(resp[0].data);
          dispatch(setBusinessId(resp[0].data.id));
          setSuggestedProducts(resp[1].data.products);
          setOnSaleProducts(resp[2].data.products);

          let store_sections: Array<StoreSections> = [];
          let products_without_salesCategories: StoreSections = {
            title: "SIN CATEGORÍA",
            id: 777,
            data: [],
          };

          resp[4].data.products.forEach((item: ProductInterface) => {
            //Find  if  category exist
            const found = store_sections.find(
              (section) => section.id === item.salesCategory?.id
            );

            if (found) {
              store_sections = store_sections.map((item_data) => {
                if (item_data.id === item.salesCategory?.id) {
                  return {
                    ...item_data,
                    data: [...item_data.data, item],
                  };
                }
                return item_data;
              });
            } else {
              if (item?.salesCategory !== null) {
                store_sections.push({
                  id: item.salesCategory?.id,
                  title: item.salesCategory?.name,
                  data: [item],
                });
              } else {
                products_without_salesCategories.data.push(item);
              }
            }
          });

          store_sections = store_sections
            .filter((item) => item.data.length > 0)
            .sort((a, b) => {
              return a?.title?.toUpperCase() > b?.title?.toUpperCase() ? 1 : -1;
            });

          setSaleCategories(
            resp[3].data.filter((category: { id: any }) => {
              if (
                store_sections.filter(
                  (store: { id: any }) => store.id === category.id
                ).length >= 1
              ) {
                return category;
              }
            })
          );

          set_products_with_no_salesCategories(
            products_without_salesCategories
          );
          setProducts(store_sections);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setPageNotFound404(true);
        });
    })();
  }, []);

  useEffect(() => {
    if (searchInputValue !== "" && products.length > 0) {
      dispatch(setResultState(true));

      let productsResults: ProductInterface[] = [];

      products.map((prod) =>
        prod.data.map((dat) => {
          if (
            normalizeString(dat.name).includes(
              normalizeString(searchInputValue)
            )
          ) {
            productsResults.push(dat);
          }
        })
      );

      setProductResults(productsResults);

      scrollToAmount(1300);
    }
  }, [products]);

  useEffect(() => {
    (async () => {
      const subTotalTemp: { [currency: string]: number } = {};

      for await (const product of cartShopping) {
        const quantity = product.quantity;
        const price = product.onSale
          ? product.onSalePrice.amount
          : product.price.price;
        const currency = product.price.codeCurrency;

        subTotalTemp[currency] =
          (subTotalTemp[currency] || 0) + price * quantity;
      }

      const formattedSubtotal = Object.entries(subTotalTemp).map(
        ([codeCurrency, prices]) => ({
          prices,
          codeCurrency,
        })
      );

      setSubTotal(formattedSubtotal);
    })();
    setAvailabilityAlert(
      cartShopping.some(
        (product) =>
          product.quantity > product.quantityInStock &&
          product.type !== "SERVICE" &&
          product.type !== "ADDON" &&
          product.type !== "MENU"
      )
    );

    if (
      initProductsCar.some((element) => element.businessName === business?.name)
    ) {
      dispatch(
        setInitProductsCar(
          initProductsCar.map((element) => {
            if (element.businessName === business?.name) {
              return {
                businessName: business?.name,
                initProductsCar: cartShopping,
              };
            } else {
              return element;
            }
          })
        )
      );
    } else {
      dispatch(
        setInitProductsCar([
          ...initProductsCar,
          { businessName: business?.name, initProductsCar: cartShopping },
        ])
      );
    }

    // dispatch(setInitProductsCar(cartShopping))
  }, [cartShopping]);

  useEffect(() => {
    setCartShopping(
      initProductsCar?.find(
        (element) => element.businessName === business?.name
      )?.initProductsCar! ?? []
    );
  }, [business]);

  //Functions that take care of filtering products when changing the currency type
  const productsFilteredByCurrencyType = () => {
    return products.map((section) => {
      const filterData = section.data.filter((prod) => {
        if (prod.onSale) {
          return (
            prod.onSalePrice.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          );
        } else {
          return prod.prices.some(
            (price) =>
              price.codeCurrency ===
              online_shop_main_currency.filter(
                (currency) => currency.current
              )[0].value
          );
        }
      });

      if (filterData.length >= 1) {
        return {
          id: section.id,
          title: section.title,
          data: filterData,
        };
      }
    });
  };
  const suggestedProductsFilteredByCurrencyType = () => {
    return suggestedProducts.filter((prod) => {
      if (prod.onSale) {
        return (
          prod.onSalePrice.codeCurrency ===
          online_shop_main_currency.filter((currency) => currency.current)[0]
            .value
        );
      } else {
        return prod.prices.some(
          (price) =>
            price.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
        );
      }
    });
  };

  const onSaleProductsFilteredByCurrencyType = () => {
    return onSaleProducts.filter((prod) => {
      if (prod.onSale) {
        return (
          prod.onSalePrice.codeCurrency ===
          online_shop_main_currency.filter((currency) => currency.current)[0]
            .value
        );
      } else {
        return prod.prices.some(
          (price) =>
            price.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
        );
      }
    });
  };
  const products_with_no_salesCategories_FilteredByCurrencyType = () => {
    return {
      data: products_with_no_salesCategories?.data.filter((prod) => {
        if (prod.onSale) {
          return (
            prod.onSalePrice.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          );
        } else {
          return prod.prices.some(
            (price) =>
              price.codeCurrency ===
              online_shop_main_currency.filter(
                (currency) => currency.current
              )[0].value
          );
        }
      }),
      id: products_with_no_salesCategories?.id,
      title: products_with_no_salesCategories?.title,
    } as StoreSections;
  };
  //-----------------------------------------------------------
  const addCart = (value: ProductInterface) => {
    let productTemp = cartShopping.find((item) => item.id === value.id);

    if (!productTemp) {
      let newProductTemp: ProductCartInterface;
      newProductTemp = {
        name: value.name,
        price: value.prices[0],
        quantity: 1,
        images: value.images[0],
        id: value.id,
        variationId: value.type === "VARIATION" ? value.variationId : null,
        suggested: value.suggested,
        onSale: value.onSale,
        onSalePrice: value.onSalePrice,
        quantityInStock: value.totalQuantity,
        type: value.type,
      };
      const cartShoppingTemp = [...cartShopping, newProductTemp];
      setCartShopping(cartShoppingTemp);
    } else {
      const cartShoppingTemp = cartShopping.map((item) => {
        if (item.id === productTemp?.id) {
          return {
            ...item,
            quantity: productTemp.quantity + 1,
          };
        } else {
          return item;
        }
      });
      setCartShopping(cartShoppingTemp);
    }

    setOpen(true);
  };

  const quantityProduct = (
    value: ProductCartInterface,
    quantityValue: number
  ) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.id === productTemp?.id) {
        return {
          ...item,
          quantity: quantityValue,
        };
      } else {
        return item;
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const removeQuantityCart = (value: ProductCartInterface) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.id === productTemp?.id) {
        return {
          ...item,
          quantity: productTemp.quantity - 1,
        };
      } else {
        return item;
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const addProductCart = (value: ProductCartInterface) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.id === productTemp?.id) {
        return {
          ...item,
          quantity: productTemp.quantity + 1,
        };
      } else {
        return item;
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const deletedProductFromCart = (value: ProductCartInterface) => {
    const cartShoppingTemp = cartShopping.filter(
      (item) => item.id !== value.id
    );
    setCartShopping(cartShoppingTemp);
  };

  const sendWhatsApp = (values: PersonalInfoInterface) => {
    if (business?.phones.length !== 0) {
      const phoneToSend = business?.phones[0].number
        .replace(/[^\w\s]/g, "")
        .replace(/\s/g, "");
      let url = `https://api.whatsapp.com/send?phone=${phoneToSend}`;

      const listProducts = cartShopping
        .map((item) => {
          let itemPrice = item.price.price;
          if (item.onSale && item.onSalePrice) {
            itemPrice = item.onSalePrice.amount;
          }
          return `(x${item.quantity}) ${
            item.name
          } - ${printPriceWithCommasAndPeriods(itemPrice * item.quantity)}`;
        })
        .join("\n");

      const cleanValue = (value: string) =>
        value.replace(/[^\w\s]/g, " ").replace(/\s/g, " ");

      const payload = `Hola!, quería ordenar un pedido.\n\nNombre: ${cleanValue(
        values.name
      )}\nDirección: ${cleanValue(values.address)}\nDescripción: ${cleanValue(
        values.description
      )}\n\n${listProducts}\n\nTotal: ${subTotal
        .map(
          (st) =>
            `${printPriceWithCommasAndPeriods(st.prices)} ${st.codeCurrency}`
        )
        .join(" ")}`;
      url += `&text=${encodeURI(payload)}`;

      window.open(url);
      setShowModalCheckoutForm(false);
    }

    // Cleaning CarShop after ordering
    setCartShopping([]);
    setOpen(false);
  };

  const showProduct = (value: ProductInterface) => {
    setSeletedProduct(value);
    setShowModal(true);
  };

  const slideLeft = () => {
    let slider: any = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    let slider: any = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const slideLeftSuggestedProducts = () => {
    let slider: any = document.getElementById("sliderSuggested");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRightSuggestedProducts = () => {
    let slider: any = document.getElementById("sliderSuggested");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const slideLeftOfertedProducts = () => {
    let slider: any = document.getElementById("sliderOferted");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRightOfertedProducts = () => {
    let slider: any = document.getElementById("sliderOferted");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();

    dispatch(setSearchInputValue(normalizeString(e.target.value)));

    dispatch(setResultState(true));

    let productsResults: ProductInterface[] = [];

    products_with_no_salesCategories_FilteredByCurrencyType().data.map(
      (prod) => {
        if (
          normalizeString(prod.name).includes(normalizeString(e.target.value))
        ) {
          productsResults.push(prod);
        }
      }
    );

    productsFilteredByCurrencyType().map((prod) =>
      prod?.data.map((dat) => {
        if (
          normalizeString(dat.name).includes(normalizeString(e.target.value))
        ) {
          productsResults.push(dat);
        }
      })
    );

    setProductResults(productsResults);

    scrollToSection("resultSection");
  };

  const handle_online_shop_main_currency_sortOptions = (index: number) => {
    set_online_shop_main_currency((prevOptions) =>
      prevOptions.map((sortOption, i) => {
        if (i === index && !sortOption.current) {
          // aplying_online_shop_main_currency_options(sortOption.value)
          dispatch(setCurrentCurrency(sortOption.value));
          return {
            ...sortOption,
            current: true,
          };
        } else if (i !== index && sortOption.current) {
          return {
            ...sortOption,
            current: false,
          };
        } else {
          return sortOption;
        }
      })
    );
  };

  if (searchInputValue === "") {
    dispatch(setResultState(false));
  }

  if (isLoading) {
    return <LoadingShop />;
  }

  if (pageNotFound404) {
    return <PageNotFound />;
  }

  return (
    <div className="bg-white" key={slug}>
      {/* Hero section */}
      {actualView === "profile-info" && (
        <ProfileInfo setActualView={setActualView} />
      )}

      {actualView === "orders-history" && (
        <OrdersHistory
          setActualView={setActualView}
          products={products}
          addCart={addCart}
        />
      )}

      {actualView === "checkout-form" && (
        <PaymentWayForm
          setActualView={setActualView}
          products={cartShopping}
          subTotal={subTotal}
          setOpen={setOpen}
          deletedProductFromCart={deletedProductFromCart}
          setCartShopping={setCartShopping}
          online_shop_main_currency={
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          }
          enable_pick_up_in_store={
            business?.configurationsKey.filter(
              (config) => config.key === "enable_pick_up_in_store"
            )[0].value === "true"
          }
        />
      )}

      {actualView === "shop" && (
        <>
          <div className="relative bg-slate-900">
            {/* Decorative image and overlay */}
            <div
              aria-hidden="true"
              className="absolute inset-0 overflow-hidden"
            >
              <img
                src={business?.banner ? business.banner?.src : ""}
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div
              aria-hidden="true"
              className="absolute inset-0 bg-slate-900 opacity-50"
            />

            {/* Navigation */}
            <div className="relative mx-auto flex max-w-3xl flex-col items-center py-32 px-6 text-center sm:py-64 lg:px-0">
              <h1 className="text-4xl uppercase font-bold tracking-tight text-white lg:text-6xl">
                {business ? business?.name : ""}
              </h1>
              <p className="mt-4 text-xl text-white">
                {business ? business?.promotionalText : ""}
              </p>
            </div>
          </div>

          <section aria-labelledby=" sale-heading  col-start-1 col-end-3">
            <div className="overflow-hidden pt-32 grid gap-y-16 sm:-mt-60 ">
              <div className="bg-white">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div
                    className={`relative ${
                      business && business?.images.length > 5
                        ? "pt-48"
                        : "-pt-8"
                    }  sm:pt-48 pb-16 sm:pb-24`}
                  >
                    <div className=" sm:w-2/4 w-auto  ">
                      {business?.description && (
                        <>
                          <h2
                            id="sale-heading"
                            className="text-2xl font-bold tracking-tight text-slate-900 md:text-3xl"
                          >
                            ¿Quiénes somos?
                          </h2>

                          <p className="text-base mt-2 text-gray-900">
                            {business?.description}
                          </p>
                        </>
                      )}
                      {business?.openHours && (
                        <div className="flex mt-5 flex-row items-center">
                          <FontAwesomeIcon
                            icon={faClock}
                            className="h-4 w-4 mr-2 text-slate-500"
                            aria-hidden="true"
                          />
                          <p className="text-base font-medium  text-gray-900">
                            {business?.openHours}
                          </p>
                        </div>
                      )}

                      {business?.phones && business?.phones.length > 0 && (
                        <div className="flex mt-2 flex-row items-center">
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="h-4 w-4 mr-2 text-slate-500"
                            aria-hidden="true"
                          />
                          <p className="text-base font-medium  text-gray-900">
                            {business?.phones
                              .map((item) => item.number)
                              .join(` ,`)}
                          </p>
                        </div>
                      )}

                      {business?.email && (
                        <div className="flex mt-2 flex-row items-center">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="h-4 w-4 mr-2 text-slate-500"
                            aria-hidden="true"
                          />
                          <p className="text-base font-medium  text-gray-900">
                            {" "}
                            {business?.email}
                          </p>
                        </div>
                      )}

                      {business?.address && (
                        <div className="flex mt-2 flex-row items-center">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            className="h-4 w-4 mr-2 text-slate-500"
                            aria-hidden="true"
                          />
                          <p className="text-base font-medium  text-gray-900">
                            {" "}
                            {(business?.address?.street_1
                              ? business?.address?.street_1 + ", "
                              : "") +
                              (business?.address?.street_2
                                ? business?.address?.street_2 + ", "
                                : "") +
                              (business?.address?.city
                                ? business.address.city + ", "
                                : "") +
                              (business?.address?.municipality?.name
                                ? business.address.municipality.name + ", "
                                : "") +
                              (business?.address?.province?.name
                                ? business?.address?.province?.name + ", "
                                : "") +
                              (business?.address?.country?.name
                                ? business?.address?.country?.name
                                : "")}
                          </p>
                        </div>
                      )}

                      <div className="flex mt-2 flex-row items-center">
                        {business?.socialNetworks.map((item) => (
                          <a
                            key={item.user}
                            href={item.url}
                            className="text-slate-400 hover:text-slate-500 mr-2"
                          >
                            <svg
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              className="h-6 w-6"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d={
                                  item.type === "FACEBOOK"
                                    ? "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                    : item.type === "WHATSAPP"
                                    ? "M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                                    : item.type === "TWITTER"
                                    ? "M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                                    : "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                }
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        ))}
                      </div>
                    </div>

                    {business && business?.images.length >= 6 && (
                      <div className="absolute mt-5 -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0">
                        <div className="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8">
                          <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                            <div className="flex-shrink-0 bg-white rounded-lg">
                              <img
                                className="h-64 w-64 rounded-lg hover:opacity-60 object-cover md:h-72 md:w-72"
                                src={business?.images[0].src}
                                alt=""
                                // onClick={() => showGallery(0)}
                              />
                            </div>

                            <div className=" mt-6 flex-shrink-0 sm:mt-0 bg-white rounded-lg">
                              <img
                                className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                                src={business?.images[1].src}
                                alt=""
                                // onClick={() => showGallery(1)}
                              />
                            </div>
                          </div>
                          <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                            <div className="flex-shrink-0 bg-white rounded-lg ">
                              <img
                                className="h-64  hover:opacity-60 w-64 rounded-lg object-cover md:h-72 md:w-72"
                                src={business?.images[2].src}
                                alt=""
                                // onClick={() => showGallery(2)}
                              />
                            </div>

                            <div className="mt-6 flex-shrink-0 sm:mt-0 bg-white rounded-lg">
                              <img
                                className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                                src={business?.images[3].src}
                                alt=""
                                // onClick={() => showGallery(3)}
                              />
                            </div>
                          </div>
                          <div className="flex space-x-6  sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                            <div className="flex-shrink-0 bg-white rounded-lg">
                              <img
                                className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                                src={business?.images[4].src}
                                alt=""
                                // onClick={() => showGallery(4)}
                              />
                            </div>

                            <div className="mt-6 flex-shrink-0 sm:mt-0 bg-white rounded-lg">
                              <img
                                className="h-64 w-64 hover:opacity-60 rounded-lg object-cover md:h-72 md:w-72"
                                src={business?.images[5].src}
                                alt=""
                                // onClick={() => showGallery(5)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <main>
            {/* Suggested product section */}
            {suggestedProductsFilteredByCurrencyType().length > 0 && (
              <section
                aria-labelledby="category-heading"
                className="pt-12 mb-5  sm:pt-16 xl:mx-auto  xl:max-w-full xl:px-8"
              >
                <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 ">
                  <h2
                    id="category-heading"
                    className="text-2xl font-bold tracking-tight text-slate-900"
                  >
                    Para ti
                  </h2>
                </div>

                <div className="mt-4  ">
                  <div className="-my-2">
                    <div className="   sm:px-6 ">
                      <div className="relative flex items-center scrollbar-hide">
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100 "
                          onClick={slideLeftSuggestedProducts}
                          size={"2x"}
                        />

                        <div
                          id="sliderSuggested"
                          className="w-full  h-full  overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                        >
                          {suggestedProductsFilteredByCurrencyType().map(
                            (item, index) => (
                              <CardProduct
                                product={item}
                                onClick={() => showProduct(item)}
                                priceSystemId={1}
                                cardWidth="w-72"
                                addCart={addCart}
                                includeShop={
                                  business?.includeShop ? true : false
                                }
                                key={index}
                              />
                            )
                          )}
                        </div>

                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                          onClick={slideRightSuggestedProducts}
                          size={"2x"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* onSale product section */}
            {onSaleProductsFilteredByCurrencyType().length > 0 && (
              <section
                aria-labelledby="category-heading"
                className="pt-12 pb-20  sm:pt-16  xl:mx-auto xl:max-w-full xl:px-8"
              >
                <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 ">
                  <h2
                    id="category-heading"
                    className="text-2xl font-bold tracking-tight text-slate-900"
                  >
                    Ofertas
                  </h2>
                </div>

                <div className="mt-4  ">
                  <div className="-my-2">
                    <div className="   sm:px-6 ">
                      <div className="relative flex items-center scrollbar-hide">
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100 "
                          onClick={slideLeftOfertedProducts}
                          size={"2x"}
                        />

                        <div
                          id="sliderOferted"
                          className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                        >
                          {onSaleProductsFilteredByCurrencyType().map(
                            (item, index) => (
                              <>
                                <CardProduct
                                  product={item}
                                  addCart={addCart}
                                  onClick={() => showProduct(item)}
                                  priceSystemId={1}
                                  cardWidth="w-72"
                                  includeShop={
                                    business?.includeShop ? true : false
                                  }
                                  key={index}
                                />
                              </>
                            )
                          )}
                        </div>

                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                          onClick={slideRightOfertedProducts}
                          size={"2x"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {saleCategories.length > 0 && searchInputValue === "" && (
              <div className="sticky top-14 z-20 bg-white pb-5 ">
                <div className="flex items-center scrollbar-hide">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="opacity-50 mx-2  cursor-pointer hover:opacity-100 "
                    onClick={slideLeft}
                    size={"2x"}
                  />
                  <div
                    id="slider"
                    className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                  >
                    {saleCategories
                      .filter((saleCategory) =>
                        productsFilteredByCurrencyType().find(
                          (item) => item?.id === saleCategory.id
                        )
                      )
                      .map((item) => (
                        <CardCategory category={item} key={item.id} />
                      ))}
                  </div>

                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                    onClick={slideRight}
                    size={"2x"}
                  />
                </div>
              </div>
            )}

            {searchResultsActive ? (
              <div className="mx-auto max-w-7xl ">
                <div id="resultSection" className="flex items-center">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="opacity-50 mx-2  cursor-pointer hover:opacity-100 "
                    size={"2x"}
                    onClick={() => {
                      dispatch(setResultState(false));
                      dispatch(setSearchInputValue(" "));
                    }}
                  />
                  <h1 className="font-bold  text-slate-500 mt-10 mb-10 flex items-center text-xl sm:text-2xl lg:text-4xl">
                    RESULTADOS{" "}
                    <span className="text-lg sm:text-xl lg:text-3xl ml-4">
                      ({productResults?.length})
                    </span>
                  </h1>
                </div>
                <div className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 ">
                  <div className="grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 md:gap-y-6 md:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-y-6 lg:gap-x-6">
                    {productResults?.map((product) => (
                      <CardProduct
                        product={product}
                        onClick={() => showProduct(product)}
                        priceSystemId={1}
                        cardWidth={"w-72"}
                        addCart={addCart}
                        includeShop={business?.includeShop ? true : false}
                        key={product.id}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className=" mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8  ">
                {saleCategories.map((item) => (
                  <ProductForCategory
                    storeSection={productsFilteredByCurrencyType().find(
                      (i) => item.id === i?.id
                    )}
                    addCart={addCart}
                    includeShop={business?.includeShop ? true : false}
                    key={item.id}
                  />
                ))}
                {products_with_no_salesCategories_FilteredByCurrencyType().data
                  .length >= 1 && (
                  <ProductForCategory
                    storeSection={products_with_no_salesCategories_FilteredByCurrencyType()}
                    addCart={addCart}
                    includeShop={business?.includeShop ? true : false}
                    key={products_with_no_salesCategories?.id}
                  />
                )}
              </div>
            )}
          </main>

          {business?.phones[0]?.number && (
            <WhatsAppButton phoneNumber={business?.phones[0]?.number!} />
          )}
        </>
      )}

      <footer className="bg-slate-50 flex  ">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8  md:order-1 md:mt-0">
            <p className="text-center text-base text-slate-400">
              &copy;{new Date().getFullYear()} {business?.name}.
            </p>
            <a
              href="https://tienda.tecopos.com"
              className="text-center text-base text-slate-400"
            >
              &copy; {new Date().getFullYear()} Tecopos Tienda. ThreeFace. Todos
              los derechos reservados. Hecho con
              <FontAwesomeIcon
                icon={faHeart}
                className="h-3 w-3 mx-1 text-slate-500"
                aria-hidden="true"
              />{" "}
              y{" "}
              <FontAwesomeIcon
                icon={faMugHot}
                className="h-3 w-3  text-slate-500"
                aria-hidden="true"
              />
              .{" "}
            </a>
          </div>
        </div>
      </footer>

      {showModalGallery && (
        <ModalGallery
          images={business?.images}
          onClick={() => setShowModalGallery(false)}
          index={indexGallery}
        />
      )}
      {showModal && (
        <ModalProduct
          product={seletedProduct}
          onClick={() => setShowModal(false)}
          addCart={addCart}
          includeShop={business?.includeShop ? true : false}
        />
      )}
      {showModalCheckoutForm && (
        <ModalCheckoutForm
          onClick={() => {
            setShowModalCheckoutForm(false);
          }}
          onSendWhatsApp={sendWhatsApp}
        />
      )}

      {showModalRegister && (
        <ModalRegister
          onClick={() => setShowModalRegister(false)}
          setShowModalLogIn={setShowModalLogIn}
        />
      )}

      {showModalLogIn && (
        <ModalLogIn
          onClick={() => {
            setShowModalLogIn(false);
            if (isLoggingToPay) {
              // setActualView("checkout-form")
              scrollToTop();
            }
            setIsLoggingToPay(false);
          }}
          setShowModalRegister={setShowModalRegister}
        />
      )}

      {openLogOutModal && (
        <Modal state={openLogOutModal} close={setOpenLogOutModal}>
          <div className="flex items-center flex-col">
            <span>¿Seguro que desea cerrar sesión?</span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
                setOpenLogOutModal(false);
                logOut();
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      {deleteCarWarning.status && (
        <Modal state={deleteCarWarning.status} close={setDeleteCarWarning}>
          <div className="flex items-center flex-col">
            <span className="text-center">
              Si cambia la moneda se vaciará el carrito.
              <br />
              ¿Seguro que desea continuar?
            </span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                handle_online_shop_main_currency_sortOptions(
                  deleteCarWarning?.index as number
                );
                setDeleteCarWarning({
                  status: false,
                  index: null,
                });
                setCartShopping([]);
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      {deleteOrderModal.state && (
        <Modal state={deleteOrderModal.state} close={setDeleteOrderModal}>
          <div className="flex items-center flex-col">
            <span className="text-center">
              ¿Seguro que desea cancelar la órden?
            </span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                CancelOrder(deleteOrderModal?.id!);
                setDeleteOrderModal({
                  state: false,
                  id: null,
                });
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      <Header
        setActualView={setActualView}
        actualView={actualView}
        business={business}
        cartShopping={cartShopping}
        searchInputValue={searchInputValue}
        setOpen={setOpen}
        handleSearchSubmit={handleSearchSubmit}
        setUserOpen={setUserOpen}
        online_shop_main_currency={online_shop_main_currency}
        setDeleteCarWarning={setDeleteCarWarning}
        handle_online_shop_main_currency_sortOptions={
          handle_online_shop_main_currency_sortOptions
        }
      />

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Bolsa de compra
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="mt-8">
                          <div className="flow-root">
                            <ul
                              role="list"
                              className="-my-4 divide-y divide-gray-200"
                            >
                              {cartShopping.map((product) => (
                                <li key={product.id} className="flex py-4">
                                  <div className="h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={
                                        product.images
                                          ? product?.images.src
                                          : imageDefault
                                      }
                                      alt={product.name}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-base font-medium text-gray-900">
                                        <div className="flex flex-row   w-32 overflow-hidden  ">
                                          <h3 className="">
                                            {product.onSale && (
                                              <FontAwesomeIcon
                                                icon={faTags}
                                                className="ml-1 text-yellow-300"
                                                size="sm"
                                                aria-hidden="true"
                                              />
                                            )}
                                            {product?.suggested && (
                                              <FontAwesomeIcon
                                                icon={faHeart}
                                                className="ml-1 text-yellow-300"
                                                size="sm"
                                                aria-hidden="true"
                                              />
                                            )}{" "}
                                            {product.name}
                                          </h3>
                                        </div>
                                        <div className="ml-4">
                                          {product.onSale && (
                                            <p className={``}>
                                              {printPriceWithCommasAndPeriods(
                                                product.onSalePrice.amount *
                                                  product.quantity
                                              ) +
                                                " " +
                                                product.onSalePrice
                                                  .codeCurrency}{" "}
                                            </p>
                                          )}
                                          <p
                                            className={` ${
                                              product.onSale &&
                                              "line-through text-slate-500"
                                            }`}
                                          >
                                            {`${printPriceWithCommasAndPeriods(
                                              product.price.price *
                                                product.quantity
                                            )} ${product.price.codeCurrency}`}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-1 mt-2 items-end justify-between text-sm">
                                      <div className="flex flex-row items-center">
                                        <FontAwesomeIcon
                                          icon={faMinusCircle}
                                          className="-ml-1 mx-2 text-gray-500 hover:text-rose-500"
                                          size="xl"
                                          aria-hidden="true"
                                          onClick={() => {
                                            if (product.quantity !== 1) {
                                              removeQuantityCart(product);
                                            } else {
                                              deletedProductFromCart(product);
                                            }
                                          }}
                                        />
                                        <input
                                          value={product.quantity}
                                          onChange={(e) =>
                                            quantityProduct(
                                              product,
                                              parseInt(e.target.value)
                                            )
                                          }
                                          name=""
                                          id=""
                                          autoComplete="quantity"
                                          className="block w-10  rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm "
                                        />
                                        <FontAwesomeIcon
                                          icon={faPlusCircle}
                                          className=" mx-2 text-gray-500 hover:text-yellow-500"
                                          size="xl"
                                          aria-hidden="true"
                                          onClick={() => {
                                            addProductCart(product);
                                          }}
                                        />

                                        {product.type === "ADDON" ||
                                        product.type === "MENU" ||
                                        product.type === "SERVICE"
                                          ? false
                                          : product.quantity >
                                              product.quantityInStock && (
                                              <span className="text-red-500">
                                                La cantidad solicitada excede a
                                                la disponible
                                              </span>
                                            )}
                                      </div>

                                      <div className="flex">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            deletedProductFromCart(product)
                                          }
                                          className="font-medium text-gray-600 hover:text-rose-500"
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="-ml-1 mx-2"
                                            size="lg"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>

                      {actualView !== "checkout-form" && (
                        <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <p>Subtotal</p>
                            <p>
                              {subTotal.map((st, key) => (
                                <p className="" key={key}>
                                  {printPriceWithCommasAndPeriods(st.prices)}{" "}
                                  {st.codeCurrency}
                                </p>
                              ))}
                            </p>
                          </div>

                          {!business?.enableManagementOrders && (
                            <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
                              * El precio final puede variar debido a los costos
                              de envío o si solicita una personalización del
                              producto. Por favor, confírmelo con el negocio.
                            </p>
                          )}

                          {business?.configurationsKey.filter(
                            (config) => config.key === "enable_pick_up_in_store"
                          )[0].value === "true" &&
                            minimun_amount_to_buy_with_delivery.find(
                              (element) =>
                                element.codeCurrency === currentCurrency
                            )?.amount! >= subTotal[0]?.prices && (
                              <p className="mt-8 text-base text-white md:order-1 md:mt-0 bg-red-500 p-2 rounded-md opacity-80">
                                Su orden debe de ser mayor que{" "}
                                {printPriceWithCommasAndPeriods(
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element) =>
                                      element.codeCurrency === currentCurrency
                                  )?.amount
                                ) +
                                  " " +
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element) =>
                                      element.codeCurrency === currentCurrency
                                  )?.codeCurrency}{" "}
                                para poder solicitar mensajería en la compra.
                              </p>
                            )}

                          {business?.configurationsKey.filter(
                            (config) => config.key === "enable_pick_up_in_store"
                          )[0].value === "false" &&
                            minimun_amount_to_buy_with_delivery.find(
                              (element) =>
                                element.codeCurrency === currentCurrency
                            )?.amount! >= subTotal[0]?.prices && (
                              <p className="mt-8 text-base text-white md:order-1 md:mt-0 bg-red-500 p-2 rounded-md opacity-80">
                                Su orden debe de ser mayor que{" "}
                                {printPriceWithCommasAndPeriods(
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element) =>
                                      element.codeCurrency === currentCurrency
                                  )?.amount
                                ) +
                                  " " +
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element) =>
                                      element.codeCurrency === currentCurrency
                                  )?.codeCurrency}{" "}
                                para realizar la compra.
                              </p>
                            )}

                          <div className="mt-6">
                            <div
                              onClick={() => {
                                if (business?.enableManagementOrders) {
                                  if (
                                    !availabilityAlert &&
                                    cartShopping.length > 0 &&
                                    (business?.configurationsKey.filter(
                                      (config) =>
                                        config.key === "enable_pick_up_in_store"
                                    )[0].value === "true"
                                      ? true
                                      : minimun_amount_to_buy_with_delivery.length <
                                        1
                                      ? true
                                      : minimun_amount_to_buy_with_delivery.find(
                                          (element) =>
                                            element.codeCurrency ===
                                            currentCurrency
                                        )?.amount! < subTotal[0]?.prices)
                                  ) {
                                    if (user !== undefined && user !== null) {
                                      setOpen(false);
                                      setActualView("checkout-form");
                                      scrollToTop();
                                    } else {
                                      setIsLoggingToPay(true);
                                      setShowModalLogIn(true);
                                    }
                                  }
                                } else {
                                  if (
                                    !availabilityAlert &&
                                    cartShopping.length > 0 &&
                                    (minimun_amount_to_buy_with_delivery.length <
                                    1
                                      ? true
                                      : minimun_amount_to_buy_with_delivery.find(
                                          (element) =>
                                            element.codeCurrency ===
                                            currentCurrency
                                        )?.amount! < subTotal[0]?.prices)
                                  ) {
                                    // setOpen(false)
                                    setShowModalCheckoutForm(true);
                                  }
                                }
                              }}
                              className={`${
                                !availabilityAlert &&
                                cartShopping.length > 0 &&
                                (business?.enableManagementOrders
                                  ? business?.configurationsKey.filter(
                                      (config) =>
                                        config.key === "enable_pick_up_in_store"
                                    )[0].value === "true"
                                    ? true
                                    : minimun_amount_to_buy_with_delivery.length <
                                      1
                                    ? true
                                    : minimun_amount_to_buy_with_delivery.find(
                                        (element) =>
                                          element.codeCurrency ===
                                          currentCurrency
                                      )?.amount! < subTotal[0]?.prices
                                  : minimun_amount_to_buy_with_delivery.length <
                                    1
                                  ? true
                                  : minimun_amount_to_buy_with_delivery.find(
                                      (element) =>
                                        element.codeCurrency === currentCurrency
                                    )?.amount! < subTotal[0]?.prices)
                                  ? " bg-yellow-400 cursor-pointer"
                                  : "bg-yellow-200"
                              } flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm `}
                            >
                              <FontAwesomeIcon
                                icon={faBagShopping}
                                className="mx-2 text-white"
                                size="sm"
                                aria-hidden="true"
                              />

                              {business?.enableManagementOrders
                                ? user === undefined || user === null
                                  ? "Ir a iniciar sesión"
                                  : "Finalizar compra"
                                : "Ordenar vía whatsapp"}
                            </div>
                            {business?.enableManagementOrders &&
                              (user === undefined || user === null) && (
                                <p className="mt-6 flex justify-center text-sm font-medium text-red-500">
                                  Para finalizar su compra debe iniciar sesión
                                </p>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={userOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setUserOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Usuario
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setUserOpen(false)}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        {!user ? (
                          <div className="w-full mt-20 flex flex-col justify-center">
                            <h2 className="font-semibold text-3xl text-center">
                              Bienvenid@
                            </h2>

                            <div className="w-full flex justify-center">
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                className="h-32 w-32 mt-10 flex-shrink-0 text-gray-300"
                                aria-hidden="true"
                              />
                            </div>

                            <div className="mt-6">
                              <div
                                onClick={() => {
                                  if (!availabilityAlert) {
                                    setShowModalLogIn(true);
                                  }
                                }}
                                className={
                                  "hover:bg-yellow-600 bg-yellow-500 cursor-pointer flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm"
                                }
                              >
                                Iniciar sesión
                              </div>
                            </div>

                            {/* <p className="text-center px-6 py-3 mt-3">ó</p> */}

                            <div className="mt-6">
                              <div
                                onClick={() => {
                                  if (!availabilityAlert) {
                                    setShowModalRegister(true);
                                  }
                                }}
                                className={
                                  "hover:bg-yellow-600 bg-yellow-500 cursor-pointer flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm"
                                }
                              >
                                Registrarte
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full mt-10 flex flex-col justify-center">
                            <div className="w-full flex justify-start">
                              {user.avatar ? (
                                <img
                                  className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300 rounded-full"
                                  src={user.avatar.src}
                                  alt=""
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faUserCircle}
                                  className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300"
                                  aria-hidden="true"
                                />
                              )}

                              <p className="text-lg">
                                {user.displayName
                                  ? user.displayName
                                  : user.email}
                              </p>
                            </div>

                            <div className="w-full mt-2 flex justify-between">
                              <button
                                className="mx-4 my-4 text-red-500"
                                onClick={() => {
                                  setOpenLogOutModal(true);
                                }}
                              >
                                Cerrar sesión
                                <FontAwesomeIcon
                                  icon={faSignOut}
                                  className="ml-1 text-red-500"
                                  size="sm"
                                  aria-hidden="true"
                                />
                              </button>

                              <button
                                className="mx-4 my-4 text-blue-500"
                                onClick={() => {
                                  setUserOpen(false);
                                  setActualView("profile-info");
                                }}
                              >
                                Ver información
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="ml-1 text-blue-500"
                                  size="sm"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>

                            <h2 className="text-3xl font-semibold mt-20 capitalize">
                              última <span className="lowercase">compra</span>
                            </h2>
                            <p className="text-gray-400">
                              Compruebe el estado de los pedidos recientes,
                              gestione las devoluciones y descubra productos
                              similares.
                            </p>

                            <button
                              className="mx-4 my-4 text-blue-500"
                              onClick={() => {
                                setUserOpen(false);
                                setActualView("orders-history");
                              }}
                            >
                              Ver historial de órdenes
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ml-1 text-blue-500"
                                size="sm"
                                aria-hidden="true"
                              />
                            </button>

                            <div className="mt-10 space-y-16 ">
                              {orders !== undefined && orders?.length >= 1 ? (
                                <>
                                  {orders
                                    ?.map((order) => (
                                      <section
                                        className="ring-1 ring-slate-200 rounded-md py-4 px-4"
                                        key={order.operationNumber}
                                        aria-labelledby={`${order.operationNumber}-heading`}
                                      >
                                        <dl className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-3 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                                          <div className="flex justify-between md:block">
                                            <dt className="font-medium text-gray-900">
                                              Orden
                                            </dt>
                                            <dd className="md:mt-1">
                                              {order.operationNumber}
                                            </dd>
                                          </div>
                                          {order?.shippingPrice?.amount > 0 && (
                                            <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                                              <dt className=" whitespace-nowrap">
                                                Envío
                                              </dt>
                                              <dd className="md:mt-1 whitespace-nowrap">
                                                {printPriceWithCommasAndPeriods(
                                                  order?.shippingPrice?.amount
                                                ) +
                                                  " " +
                                                  order?.shippingPrice
                                                    ?.codeCurrency}
                                              </dd>
                                            </div>
                                          )}
                                          <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                                            <dt>Total</dt>
                                            <dd className="md:mt-1">
                                              {printPriceWithCommasAndPeriods(
                                                order?.totalToPay[0]?.amount
                                              ) +
                                                " " +
                                                order?.totalToPay[0]
                                                  ?.codeCurrency}
                                            </dd>
                                          </div>

                                          <div className="col-span-2">
                                            <div className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-2 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                                              <div className=" col-span-1  pt-4 md:block md:pt-0">
                                                <dt className="font-medium text-gray-900 mt-2  ">
                                                  Creada
                                                </dt>
                                                <dd className="md:mt-1">
                                                  <time
                                                    className="text-gray-500 font-semibold"
                                                    dateTime={order.createdAt}
                                                  >
                                                    {formatDateTime(
                                                      order.createdAt
                                                    )}
                                                  </time>
                                                </dd>
                                              </div>

                                              <div className=" col-span-1  pt-4 font-medium text-gray-900 md:block md:pt-0">
                                                <dt className="mt-2  whitespace-nowrap">
                                                  Estado
                                                </dt>
                                                <dd className="md:mt-1 text-gray-500 font-semibold  whitespace-nowrap">
                                                  {filterOrdenStatus(
                                                    order?.status
                                                  )}
                                                </dd>
                                              </div>
                                            </div>
                                          </div>
                                        </dl>
                                        <div className="mb-2 mt-6 flow-root divide-y divide-gray-200 border-t border-gray-200">
                                          {order.selledProducts.map(
                                            (product) => {
                                              const matchedProduct =
                                                findMatchingProduct(
                                                  product.name,
                                                  products
                                                );

                                              return (
                                                <div
                                                  key={product.id}
                                                  className="pt-6 sm:flex sm:flex-col"
                                                >
                                                  <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                                                    <img
                                                      src={
                                                        product.image?.src
                                                          ? product.image.src
                                                          : imageDefault
                                                      }
                                                      alt={
                                                        product.image?.src
                                                          ? product.image.src
                                                          : imageDefault
                                                      }
                                                      className="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-32 sm:w-32"
                                                    />
                                                    <div className="min-w-0 flex-1 pt-1.5 sm:pt-0">
                                                      <h3 className="text-sm font-medium text-gray-900">
                                                        {product.name}
                                                      </h3>
                                                      <p className="truncate text-sm text-gray-500">
                                                        <span>
                                                          {product.quantity +
                                                            " U"}
                                                        </span>
                                                      </p>
                                                      <p className="mt-1 font-medium text-gray-900">
                                                        {product?.priceUnitary
                                                          ?.amount +
                                                          " " +
                                                          product?.priceUnitary
                                                            ?.codeCurrency}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {matchedProduct !==
                                                    undefined && (
                                                    <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                                      <button
                                                        type="button"
                                                        className="flex w-32 sm:w-40 whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 bg-yellow-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                                        onClick={() =>
                                                          addCart(
                                                            matchedProduct
                                                          )
                                                        }
                                                      >
                                                        Comprar otra vez
                                                      </button>
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                        {order?.paymentGateway?.code ===
                                          "G_TROPIPAY" && (
                                          <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                            <button
                                              type="button"
                                              className="flex w-full whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 bg-blue-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                              onClick={() => {
                                                CreateTropipayLink(order.id);
                                              }}
                                            >
                                              Volver a generar link de pago
                                            </button>
                                          </div>
                                        )}
                                        {(order.status === "PAYMENT_PENDING" ||
                                          order.status === "CREATED") && (
                                          <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                            <button
                                              type="button"
                                              className="flex w-full whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-600 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                              onClick={() => {
                                                setDeleteOrderModal({
                                                  state: true,
                                                  id: order.id,
                                                });
                                              }}
                                            >
                                              Cancelar orden
                                            </button>
                                          </div>
                                        )}
                                      </section>
                                    ))
                                    .slice(0, 1)}
                                </>
                              ) : (
                                <p className="text-center text-slate-400 my-10">
                                  No hay ordenes registradas aún
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
