import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BusinessShopPage } from "../pages/BusinessShopPage";
import { useServerOrders } from "../services/useServerOrders";
import { useAppSelector } from "../store/hooks";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentFailed from "../pages/PaymentFailed";
import PaymentSuccess from "../pages/PaymentSuccess";

export const MainRoute = () => {
  const { getMyOrders } = useServerOrders();

  const { key } = useAppSelector((state) => state.session);

  useEffect(() => {   

    if (key !== null) {
      getMyOrders({ per_page: 5 });
    }
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={true}
        theme="light"
        transition={Flip}
      />
      <Routes>
        {/* <Route path='/' element={<App />} /> */}
        <Route path="/" element={<BusinessShopPage />} />
        {/* <Route path='/:slug' element={<BusinessShopPage />} /> */}
        <Route path="/:slug/paymentfailed" element={<PaymentFailed />} />
        <Route path="/:slug/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/*" element={<BusinessShopPage />} />
      </Routes>
    </BrowserRouter>
  );
};
