import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import useServer from "../../../services/useServerMain";
import Input from "../../forms/Input";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

interface Props {
   onClick: Function;
   setShowModalLogIn: any
}

const ModalRegister = ({ onClick, setShowModalLogIn }: Props) => {

   const { registerUser, isFetching } = useServer();

   const { handleSubmit, control } = useForm();
   const notify = () => toast.error('La contraseña de confirmación no coincide!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
   });

   const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
      values
   ) => {
      const { email, password, confirm_password } = values
      const data = {
         email,
         password
      }
      if (confirm_password !== password) {
         notify()
      } else {
         registerUser(data, onClick)
      }
   };

   const [viewPasw, setViewPasw] = useState(false);
   const [viewPaswConfirm, setviewPaswConfirm] = useState(false);


   return (
      <Transition.Root show={true} as={Fragment}>

         <Dialog
            as="div"
            className="relative z-40"
            onClose={() => onClick()}
         >
            <Transition.Child
               as={Fragment}
               enter="ease-out duration-60"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-20"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <div className="fixed inset-0 backdrop-blur-md backdrop-filter bg-gray-500 bg-opacity-75 transition-opacity md:block" />
            </Transition.Child>

            <div className="rounded-lg fixed inset-0 z-10 overflow-y-auto">
               <div className="rounded-lg flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                  <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                  >
                     <Dialog.Panel className="flex px-4 py-24 w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">

                        <div className="rounded-lg relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                           <button
                              type="button"
                              className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                              onClick={() => onClick()}
                           >
                              <FontAwesomeIcon
                                 icon={faTimes}
                                 className="h-6 w-6"
                                 aria-hidden="true"
                              />
                           </button>

                           <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                 <img
                                    className="mx-auto h-10 w-auto"
                                    src="/logo512.png"
                                    alt="Your Company"
                                 />
                                 <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                    Registra tu cuenta
                                 </h2>
                              </div>


                              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                 <form className="space-y-2" action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>

                                    <div>
                                       <Input
                                          name="email"
                                          control={control}
                                          rules={{ required: "Campo requerido" }}
                                          label="Dirección Email"
                                       />
                                    </div>

                                    <div className="relative">
                                       <Input
                                          name="password"
                                          control={control}
                                          rules={{ required: "Campo requerido" }}
                                          label="Contraseña"
                                          type={viewPasw ? "text" : "password"}
                                       />
                                       {!viewPasw ? (
                                          <EyeIcon
                                             className="absolute top-11 right-2 h-5 text-gray-700 hover:cursor-pointer"
                                             onClick={() => setViewPasw(!viewPasw)}
                                          />
                                       ) : (
                                          <EyeSlashIcon
                                             className="absolute top-11 right-2 h-5 text-gray-700 hover:cursor-pointer"
                                             onClick={() => setViewPasw(!viewPasw)}
                                          />
                                       )}
                                    </div>


                                    <div className="relative">
                                       <Input
                                          name="confirm_password"
                                          control={control}
                                          rules={{ required: "Campo requerido" }}
                                          label="Confirmar contraseña"
                                          type={viewPasw ? "text" : "password"}
                                       />
                                       {!viewPaswConfirm ? (
                                          <EyeIcon
                                             className="absolute top-11 right-2 h-5 text-gray-700 hover:cursor-pointer"
                                             onClick={() => setviewPaswConfirm(!viewPaswConfirm)}
                                          />
                                       ) : (
                                          <EyeSlashIcon
                                             className="absolute top-11 right-2 h-5 text-gray-700 hover:cursor-pointer"
                                             onClick={() => setviewPaswConfirm(!viewPaswConfirm)}
                                          />
                                       )}
                                    </div>


                                    <div>
                                       <button
                                          type="submit"
                                          className="flex w-full justify-center rounded-md  bg-yellow-400  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                       >

                                          {isFetching ? "Cargando..." : "Registrar cuenta"}
                                       </button>
                                    </div>

                                    <div className="flex items-center justify-between">
                                       <div className="text-sm leading-6">
                                          <span className="font-semibold text-blue-500 cursor-pointer" onClick={() => {
                                             setShowModalLogIn(true)
                                             onClick()
                                          }}>
                                             Ir a iniciar sesión
                                          </span>
                                       </div>
                                    </div>

                                 </form>



                              </div>
                           </div>

                        </div>
                     </Dialog.Panel>
                  </Transition.Child>
               </div>
            </div>
         </Dialog>

      </Transition.Root>
   )
}

export default ModalRegister
