/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from "../../components/modals/GenericModal";
import { useState } from 'react'
import EditProfile from "./EditProfile";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import {
    faArrowRight,
    faArrowUpRightFromSquare,
    faPencilSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ProfileInfo = ({ setActualView }: { setActualView: any }) => {

    const [openEditProfile, setOpenEditProfile] = useState<boolean>(false)
    const [openLogOutModal, setOpenLogOutModal] = useState<boolean>(false)

    const { user } = useAppSelector(state => state.session)

    const logOut = () => {
    }

    return (
        <>
            <div className="mx-auto max-w-7xl sm:px-2 lg:px-8 mt-40">

                <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">

                    <div className="flex justify-between">
                        <h1 className="text-2xl font-bold tracking-tight text-blue-950 sm:text-3xl mt-6 lg:mt-0">Información personal</h1>
                        <button
                            className="mx-4 my-4 text-blue-500"
                            onClick={() => {
                                setActualView("shop");
                            }}
                        >
                            Regresar a la tienda
                            <FontAwesomeIcon
                                icon={
                                    faArrowRight
                                }
                                className="ml-1 text-blue-500"
                                size="sm"
                                aria-hidden="true"
                            />
                        </button>
                    </div>

                    <div className="mt-6">

                        <div className="grid grid-cols-1 sm:grid-cols-2">

                            {
                                user?.displayName && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Nombre</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.displayName}</dd>
                                    </div>
                                )
                            }

                            {
                                user?.email && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Correo electrónico</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.email}</dd>
                                    </div>
                                )
                            }

                            {/* {
                                user?.firstName && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Nombre</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.firstName}</dd>
                                    </div>
                                )
                            }


                            {
                                user?.lastName && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Apellido</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.lastName}</dd>
                                    </div>
                                )
                            }

                            {
                                ((user?.phones && user?.phones.length >= 1) && user?.phones[0].number) && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Número de teléfono</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.phones[0].number}</dd>
                                    </div>
                                )
                            }




                            {
                                user?.sex && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Sexo</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.sex === "male" ? "Masculino" : "Femenino"}</dd>
                                    </div>
                                )
                            }


                            {
                                user?.birthAt && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Fecha de cumpleaños</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.birthAt}</dd>
                                    </div>
                                )
                            }

                            {
                                (user?.address != null && user?.address.street_1) && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Calle</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">{user?.address.street_1}</dd>
                                    </div>
                                )
                            }
                            {
                                (user?.address != null && user?.address.street_2) && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Y calle</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.address.street_2}</dd>
                                    </div>
                                )
                            }

                            {
                                (user?.address != null && user?.address.country.name) && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">País</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.address.country.name}</dd>
                                    </div>
                                )
                            }


                            {
                                (user?.address != null && user?.address.province.name) && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Provincia</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.address.province.name}</dd>
                                    </div>
                                )
                            }


                            {
                                (user?.address != null && user?.address.municipality.name) && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Municipio</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.address.municipality.name}</dd>
                                    </div>
                                )
                            }

                            {
                                (user?.address != null && user?.address.city) && (
                                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Ciudad</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.address.city}</dd>
                                    </div>
                                )
                            } */}
                        </div>

                    </div>

                    <div className="flex justify-between mt-8 w-full lg:w-1/2">
                        <p
                            className={'px-4 py-2 text-sm text-red-500 cursor-pointer flex items-center font-medium'}
                            onClick={() => setOpenLogOutModal(true)}
                        >
                            Cerrar sesión
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                className="h-6 mt-1 ml-4 cursor-pointer"
                                size="sm"
                                aria-hidden="true"
                            />
                        </p>
                        <button
                            className={'px-4 py-2 text-sm text-blue-900 hover:text-blue-800 cursor-pointer font-medium flex items-center justify-end'}
                            onClick={() => setOpenEditProfile(true)}
                        >
                            Editar
                            <FontAwesomeIcon
                                icon={faPencilSquare}
                                className="h-6 mt-1 ml-4 cursor-pointer"
                                size="sm"
                                aria-hidden="true"
                            />
                        </button>
                    </div>

                </div >



            </div >

            {
                openEditProfile && (
                    <Modal state={openEditProfile} close={setOpenEditProfile} size="m">
                        <EditProfile />
                    </Modal>
                )
            }
            {
                openLogOutModal && (
                    <Modal state={openLogOutModal} close={setOpenLogOutModal}>
                        <div className='flex items-center flex-col'>
                            <span>
                                ¿Seguro que desea cerrar sesión?
                            </span>

                            <button
                                className='bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4'
                                onClick={(e) => {
                                    e.preventDefault()
                                    setOpenLogOutModal(false)
                                    logOut()
                                }}
                            >
                                Aceptar
                            </button>
                        </div>
                    </Modal>
                )
            }
        </>
    )
}

export default ProfileInfo
