import { useState } from "react";
import Modal from "./modals/GenericModal";
import TextArea from "./forms/TextArea";
import { useForm } from "react-hook-form";

interface WhatsAppButtonProps {
  phoneNumber: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {

  const [showModal, setshowModal] = useState(false)

  const { control, watch } = useForm()

  return (
    <>
      {
        !showModal && (
          <button
            className="fixed bottom-5 right-5 z-30 bg-green-500 hover:bg-green-600 text-white py-4 px-6 rounded-full shadow-lg"
            onClick={() => {
              setshowModal(true)
            }}
          >
            <img
              className="w-6 h-6"
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp"
            />
          </button>
        )
      }

      {
        showModal && (
          <Modal state={showModal} close={setshowModal}>
            <form className="w-full" method="action">
              <h2 className="text-xl font-semibold">¿Desea contactar con nosotros?</h2>
              <TextArea name="mesage" control={control} label="Envíenos un mensaje" maxDigits={80} />

              <p className="font-thin text-end">{watch("mesage")?.length ?? "0"}/80</p>

              <button
                type="submit"
                className="h-12 w-full rounded-lg p-2 bg-green-500 hover:bg-green-600 text-white mt-5 font-semibold text-lg"
                onClick={(e) => {
                  e.preventDefault()
                  window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${watch("mesage")}`)
                  setshowModal(false)
                }}
              >Enviar</button>
            </form>
          </Modal>
        )
      }
    </>
  );
};

export default WhatsAppButton;
