export const orderStatus: {
  code: string;
  value: string;
  color: string;
}[] = [
  {
    code: "WITH_ERRORS",
    value: "Con errores",
    color: "red",
  },
  {
    code: "CREATED",
    value: "Creada",
    color: "blue",
  },
  {
    code: "PAYMENT_PENDING",
    value: "Pendiente de pago",
    color: "yellow",
  },
  {
    code: "IN_PROCESS",
    value: "Procesando",
    color: "blue",
  },
  {
    code: "BILLED",
    value: "Facturada",
    color: "green",
  },
  {
    code: "CANCELLED",
    value: "Cancelada",
    color: "red",
  },
  {
    code: "REFUNDED",
    value: "Reembolsada",
    color: "yellow",
  },
  {
    code: "COMPLETED",
    value: "Completada",
    color: "green",
  },
  {
    code: "IN_TRANSIT",
    value: "En tránsito",
    color: "blue",
  },
  {
    code: "DELIVERED",
    value: "Entregada",
    color: "green",
  },
];
