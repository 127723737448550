

import { useEffect } from 'react'
import { useLocation, useParams, Link } from "react-router-dom"
import { useServerOrders } from '../services/useServerOrders'
import LoadingSpin from '../components/misc/LoadingSpin'
import { printPriceWithCommasAndPeriods } from '../utils/functions'
import imageDefault from "../assets/jpg/no-imagen-shop.jpg"

const PaymentSuccess = () => {

  const { getOrderDetails, orderDetails, isLoading } = useServerOrders()

  const { search } = useLocation()
  const { slug } = useParams()

  function parseQueryString(queryString: string | string[][] | Record<string, string> | URLSearchParams | undefined) {
    const params = new URLSearchParams(queryString);
    const bankOrderCode = params.get('bankOrderCode');
    const reference = params.get('reference');
    const state = params.get('state');

    return { bankOrderCode, reference, state };
  }

  const parsedParams = parseQueryString(search);

  const { bankOrderCode, reference } = parsedParams

  useEffect(() => {

    getOrderDetails(parseInt(reference!))

  }, [])

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-base font-medium text-indigo-600">¡Gracias!</h1>
          <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">¡Orden confirmada!</p>
          <p className="mt-2 text-base text-gray-500">Su orden <span className='text-indigo-600 text-base font-medium'>#{orderDetails?.operationNumber}</span> ha sido confirmada y pronto comenzará a procesarse.</p>

          <dl className="mt-12 text-sm font-medium">
            <dt className="text-gray-900">Código TropiPay de estado del pedido bancario</dt>
            <dd className="mt-2 text-indigo-600">{bankOrderCode}</dd>
          </dl>
        </div>


        <div className="mt-10 border-t border-gray-200">
          <h2 className="sr-only">Tu orden</h2>

          {
            isLoading ?
              <div className='w-full flex items-center justify-center mt-20'>
                <LoadingSpin color='black' />
              </div>
              : (
                <div className="sm:ml-40 sm:pl-6">

                  <h3 className="sr-only">Items</h3>
                  {orderDetails?.selledProducts.map((product) => (
                    <div key={product.id} className="flex space-x-6 border-b border-gray-200 py-10">
                      <img
                        src={product?.image
                          ? product?.image.src
                          : imageDefault
                        }
                        alt={product?.name}
                        className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                      />
                      <div className="flex flex-auto flex-col">
                        <div>
                          <h4 className="font-medium text-gray-900">
                            <p>{product?.name}</p>
                          </h4>
                          <p className="mt-2 text-sm text-gray-600">{product?.observations}</p>
                        </div>
                        <div className="mt-6 flex flex-1 items-end">
                          <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                            <div className="flex">
                              <dt className="font-medium text-gray-900">Cantidad</dt>
                              <dd className="ml-2 text-gray-700">{product?.quantity}</dd>
                            </div>
                            <div className="flex pl-4 sm:pl-6">
                              <dt className="font-medium text-gray-900">Precio</dt>
                              <dd className="ml-2 text-gray-700">{printPriceWithCommasAndPeriods(product?.priceUnitary?.amount) + " " + product.priceUnitary.codeCurrency}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  ))}
                  <h3 className="sr-only">Tu informacion</h3>

                  <h4 className="sr-only">Direcciones</h4>
                  <dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
                    <div>
                      <dt className="font-medium text-gray-900">Dirección de envío</dt>
                      <dd className="mt-2 text-gray-700">
                        <address className="not-italic">
                          <span className="block">{orderDetails?.shipping?.country?.name}</span>
                          <span className="block">{orderDetails?.shipping?.province?.name}</span>
                          <span className="block">{orderDetails?.shipping?.municipality?.name}</span>
                          <span className="block">{orderDetails?.shipping?.city}</span>
                          <span className="block">{orderDetails?.shipping?.street_1}</span>
                          <span className="block">{orderDetails?.shipping?.street_2}</span>
                        </address>
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">Dirección de facturación</dt>
                      <dd className="mt-2 text-gray-700">
                        <address className="not-italic">
                          <span className="block">{orderDetails?.billing?.country?.name}</span>
                          <span className="block">{orderDetails?.billing?.province?.name}</span>
                          <span className="block">{orderDetails?.billing?.municipality?.name}</span>
                          <span className="block">{orderDetails?.billing?.city}</span>
                          <span className="block">{orderDetails?.billing?.street_1}</span>
                          <span className="block">{orderDetails?.billing?.street_2}</span>
                        </address>
                      </dd>
                    </div>
                  </dl>

                  <h4 className="sr-only">Pago</h4>
                  <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
                    <div>
                      <dt className="font-medium text-gray-900">Método de pago</dt>
                      <dd className="mt-2 text-gray-700">
                        <p>{orderDetails?.paymentGateway?.name}</p>
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">Métodos de envío</dt>
                      <dd className="mt-2 text-gray-700">
                        <p>{orderDetails?.pickUpInStore ? "Recogida en tienda" : "Mensajería"}</p>
                      </dd>
                    </div>
                  </dl>

                  <h3 className="sr-only">Resumen</h3>

                  <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
                    {
                      orderDetails?.shippingPrice !== null && (
                        <>
                          <div className="flex justify-between">
                            <dt className="font-medium text-gray-900">Subtotal</dt>
                            <dd className="text-gray-700">{printPriceWithCommasAndPeriods(orderDetails?.totalToPay[0]?.amount! - orderDetails?.shippingPrice?.amount!) + " " + orderDetails?.totalToPay[0]?.codeCurrency}</dd>
                          </div>

                          <div className="flex justify-between">
                            <dt className="font-medium text-gray-900">Envío</dt>
                            <dd className="text-gray-700">{printPriceWithCommasAndPeriods(orderDetails?.shippingPrice?.amount) + " " + orderDetails?.shippingPrice?.codeCurrency}</dd>
                          </div>
                        </>
                      )
                    }

                    <div className="flex justify-between">
                      <dt className="font-medium text-gray-900">Total</dt>
                      <dd className="text-gray-700">{printPriceWithCommasAndPeriods(orderDetails?.totalToPay[0]?.amount!) + " " + orderDetails?.totalToPay[0]?.codeCurrency}</dd>

                    </div>
                  </dl>


                  <div className="mt-16 border-t border-gray-200 py-6 text-right">
                    <Link to={`/${slug}`} className='text-sm font-medium text-indigo-600 hover:text-indigo-500'>
                      Continuar comprando
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess
