import { createSlice } from "@reduxjs/toolkit";
import { ProductCartInterface } from "../../interfaces/LocalInterfaces";

interface InitialInterface {
    initProductsCar: {
        businessName: string;
        initProductsCar: ProductCartInterface[];
    }[];
}

const initialState: InitialInterface = {
    initProductsCar: []
};

const InitSlice = createSlice({
    initialState,
    name: "initSclice",
    reducers: {
        setInitProductsCar: (state, action) => ({
            ...state,
            initProductsCar: action.payload
        })
    },
});

export const { setInitProductsCar } = InitSlice.actions;

export default InitSlice.reducer;
